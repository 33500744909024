///list grid (array-based) with big title on the side
/// It pulls data from an array that's passed as a prop

import React from 'react'
import Link from '../../utilities/link'
import SlideInTop from '../utility/slideInTopAnimation'
import SlideInBottom from '../utility/slideInBottomAnimation'
import Card from '../common/card'

const CardList = props => {
  return (
    <div className={`${props.gradient}`}>
      <div className="section">
        <div className="md:flex py-12 md:py-24 md:space-x-12 space-y-8 md:space-y-0">
          <div className={`md:w-1/3 ${props.titleColor}`}>
            <SlideInTop>
              <h2 className="text-3xl font-semibold border-b-2 border-green-400 pb-4">{props.sectionTitle}</h2>
            </SlideInTop>
            <SlideInBottom>
              <p className='mt-4'>{props.sectionDesc}</p>
            </SlideInBottom>
            {props.illustration && <div>{props.illustration}</div>}
          </div>         
          <div className='md:w-2/3'>
            <div className="md:grid md:grid-cols-2 md:gap-8 place-items-stretch">
              {props.features.map((feature, idx) => (
                  <div key={feature}>
                    <Card
                      cardTitle={props.featureTitle[idx]}
                      cardSubtitle={props.featureDesc[idx]}
                      button={props.featureButton ? props.featureButton[idx] : ''}
                      to={props.featureButtonLink ? props.featureButtonLink[idx] : ''}
                    />
                  </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
      
  )
}

export default CardList

