import React from 'react'
import { graphql } from 'gatsby'
import CommonFooter from '../components/footer/commonFooter'
import IndexHero from '../components/header/indexHero'
import Section2 from '../components/sections/section2'
import Section5 from '../components/sections/section5'
import Section6 from '../components/sections/section6'
import Section16 from '../components/sections/section16'
import Seo from '../components/seo/seo'
import { useTranslation } from 'react-i18next'
import Modal from '../components/common/temporisedModal'
import GeneralSignUp from '../components/forms/generalSignUpForm'
import CardList from '../components/common/cardList'
import Card from '../components/common/card'
import Paragraph2Cols from '../components/common/paragraphTwoCols'

const IndexPage = ({ data }) => {
  const { t } = useTranslation()
  return (
    <>
      <Seo
        title={t('index.seoTitle')}
        description={t('index.seoDescription')}
        seoImage={data.indexImage.childImageSharp.resize.src}
      />
      {/* <Modal
        component={<GeneralSignUp />}
      /> */}
      <IndexHero 
        heroImage={data.indexImage.childImageSharp.fluid} 
      />
      <div className="max-w-7xl md:mx-auto px-16 md:px-0 py-12 md:py-24">
        <div className="md:flex md:space-x-24 space-y-12 md:space-y-0">
          <Card
            cardTitle={t('index.introTitle')}
            cardSubtitle={t('index.introDesc')}
            button="Servizi produttivi"
            to="/servizi/produzione-schede-elettroniche"
          />    
          <Card
            cardTitle={t('index.introTitle2')}
            cardSubtitle={t('index.introDesc2')}
            button="Servizi progettuali"
            to="/servizi/progettazione-elettronica"
          />
        </div>
      </div>
      <div className='maingrad'>
        <CardList
          gradient=""
          titleColor="text-white"
          sectionTitle={t('index.exploreTitle')}
          sectionDesc={t('index.exploreDesc')}
          features={[1, 2, 3]}
          featureTitle={[
            <>{t('index.service1')}</>,
            <>{t('index.service2')}</>,
            <>{t('index.service3')}</>
          ]}
          featureDesc={[
            <>{t('index.service1desc')}</>,
            <>{t('index.service2desc')}</>,
            <>{t('index.service3desc')}</>
          ]}
          featureButton={[
            <>{t('index.serviceButton')}</>,
            <>{t('index.serviceButton')}</>,
            <>{t('index.serviceButton')}</>
          ]}
          featureButtonLink={[
            '/assemblaggio-schede-elettroniche',
            '/servizi/produzione-schede-elettroniche',
            '/servizi/conformal-coating',
          ]}
        />
        <div className="md:-mt-48">
        <CardList
          gradient=""
          titleColor="text-white"
          sectionTitle={t('index.engTitle')}
          sectionDesc={t('index.engDesc')}
          features={[1, 2, 3]}
          featureTitle={[
            <>{t('index.eng1')}</>,
            <>{t('index.eng2')}</>,
            <>{t('index.eng3')}</>
          ]}
          featureDesc={[
            <>{t('index.eng1desc')}</>,
            <>{t('index.eng2desc')}</>,
            <>{t('index.eng3desc')}</>
          ]}
          featureButton={[
            <>{t('index.engButton')}</>,
            <>{t('index.engButton')}</>,
            <>{t('index.engButton')}</>
          ]}
          featureButtonLink={[
            '/servizi/progettazione-elettronica',
            '/servizi/sviluppo-firmware',
            '/servizi/sviluppo-software',
          ]}
        />
        </div>
      </div>
        <CardList
          gradient="bg-gay-100"
          titleColor="text-gray-700"
          sectionTitle={t('index.prodTitle')}
          sectionDesc={t('index.prodDesc')}
          features={[1, 2, 3, 4]}
          featureTitle={[
            <>{t('index.prod1')}</>,
            <>{t('index.prod2')}</>,
            <>{t('index.prod3')}</>,
            <>{t('index.prod4')}</>
          ]}
          featureDesc={[
            <>{t('index.prod1desc')}</>,
            <>{t('index.prod2desc')}</>,
            <>{t('index.prod3desc')}</>,
            <>{t('index.prod4desc')}</>
          ]}
          featureButton={[
            <>{t('index.prodButton')}</>,
            <>{t('index.prodButton')}</>,
            <>{t('index.prodButton')}</>,
            <>{t('index.prodButton')}</>
          ]}
          featureButtonLink={[
            '/prodotti/hera-laundry',
            '/prodotti/fiber-el6',
            '/prodotti/schede-rele-interfaccia-plc',
            '/prodotti/sonde-di-temperatura',
          ]}
        />

        <Section2
          fixed={data.world.childImageSharp.fixed}
          alt={t('index.altWorldIcon')}
          section2Title={t('index.discoverTitle')}
          card1Title={t('index.discover1')}
          card2Title={t('index.discover2')}
          card3Title={t('index.discover3')}
          card4Title={t('index.discover4')}
        />
        <Paragraph2Cols
          title={t('index.historyTitle')}
          intro={t('index.historyIntro')}
          text={t('index.historyText')}
        />
        <Section6
          title={t('index.featureTitle')}
          top={t('index.feature1')}
          secondRow1={t('index.feature2')}
          secondRow2={t('index.feature3')}
          thirdRow1={t('index.feature4')}
          thirdRow2={t('index.feature5')}
          bottom1={t('index.feature6')}
          bottom2={t('index.feature7')}
        />
        
      <CommonFooter />
    </>
  )
}

  


export default IndexPage

export const imgQuery = graphql`
  query {
    indexImage: file(relativePath: { eq: "heros/home/industrial-electronics.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
    world: file(relativePath: { eq: "home/ctaElectronicsWorld.png" }) {
      childImageSharp {
        fixed(width: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sensoristica: file(relativePath: { eq: "icons/Sensoristica.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    schedapotenza: file(relativePath: { eq: "icons/SchedaIO.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    hera: file(relativePath: { eq: "icons/HERA.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    el6: file(relativePath: { eq: "icons/EL6.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sviluppoSoftware: file(relativePath: { eq: "icons/SviluppoSoftware.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    progettazioneElettronica: file(
      relativePath: { eq: "icons/ProgettazioneElettronica.png" }
    ) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    contractElectronicsManufacturing: file(
      relativePath: { eq: "icons/ContractElectronicsManufacturing.png" }
    ) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
