import React from 'react'
import { StaticQuery, Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SiteNavigation from './siteNavigation'
import Card from '../common/card'
import SvgCTA from '../../illustrations/Home/CTAElectronics'
import { useTranslation } from 'react-i18next'

const IndexHero = (props) => {
  const { t } = useTranslation()
  return(
    <StaticQuery
    query={graphql`
      query {
        schedaIO: file(relativePath: { eq: "icons/SchedaIO.png" }) {
          childImageSharp {
            fixed(width: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        CPU: file(relativePath: { eq: "icons/CPU.png" }) {
          childImageSharp {
            fixed(width: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        el6: file(relativePath: { eq: "icons/EL6.png" }) {
          childImageSharp {
            fixed(width: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        hera: file(relativePath: { eq: "icons/HERA.png" }) {
          childImageSharp {
            fixed(width: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <SiteNavigation />
        <section
          className="maingrad relative py-4 md:py-48"
        >
          <Img
            fluid={props.heroImage}
            style={{
              position: 'absolute',
              overflow: 'hidden',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              opacity: 0.2,
            }}
            alt={t('indexHero.altHeroImage')}
          />

          <div
            className='invisible md:visible'
            style={{
              position: 'absolute',
              zIndex: 1,
              overflow: 'hidden',
              bottom: 0,
              top: 0,
              height: '100%',
              width: '100%',
            }}
          >
            <SvgCTA />
          </div>

          <div className="md:max-w-7xl md:mx-auto px-4 md:px-0 ">
              <div className="md:flex md:items-center md:space-x-8 space-y-4 z-20">
                  <div className="maingrad p-8 z-20 md:w-1/2">
                    <h1 className="text-5xl text-white font-semibold">CTA Electronics</h1>
                    <h2 className="font-semibold">{t('index.tagLine')}</h2>
                  </div>
                <div className="md:w-1/2 grid grid-cols-1 md:grid-cols-2 gap-4 z-20">
                  <Card
                    cardTitle={t('indexHero.feature1')}
                    cardSubtitle={t('indexHero.feature1Desc')}
                    imageCss={{ top: 0, left: 0, right: 0, bottom: 0 }}
                    imageStyle={{
                      position: `relative`,
                      width: '80px',
                      height: '80px',
                    }}
                    fixedImage={data.CPU.childImageSharp.fixed}
                    alt={t('indexHero.altCPUIcon')}
                  />
                  <Card
                    cardTitle={t('indexHero.feature2')}
                    cardSubtitle={t('indexHero.feature2Desc')}
                    imageCss={{ top: 0, left: 0, right: 0, bottom: 0 }}
                    imageStyle={{
                      position: `relative`,
                      width: '80px',
                      height: '80px',
                    }}
                    fixedImage={data.schedaIO.childImageSharp.fixed}
                    alt={t('indexHero.altPowerBoardIcon')}
                  />
                  <Card
                    cardTitle={t('indexHero.feature3')}
                    cardSubtitle={t('indexHero.feature3Desc')}
                    imageCss={{ top: 0, left: 0, right: 0, bottom: 0 }}
                    imageStyle={{
                      position: `relative`,
                      width: '80px',
                      height: '80px',
                    }}
                    fixedImage={data.el6.childImageSharp.fixed}
                    alt={t('indexHero.altEL6Icon')}
                  />
                  <Card
                    cardTitle={t('indexHero.feature4')}
                    cardSubtitle={t('indexHero.feature4Desc')}
                    imageCss={{ top: 0, left: 0, right: 0, bottom: 0 }}
                    imageStyle={{
                      position: `relative`,
                      width: '80px',
                      height: '80px',
                    }}
                    fixedImage={data.hera.childImageSharp.fixed}
                    alt={t('indexHero.altHERAIcon')}
                  />
                </div>
              </div>
          </div>
        </section>
      </>
    )}
  />
  )
}

export default IndexHero
