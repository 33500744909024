///list grid (array-based) with big title on the side
/// It pulls data from an array that's passed as a prop

import React from 'react'
import Link from '../../utilities/link'
import Img from 'gatsby-image'
import SlideInTop from '../utility/slideInTopAnimation'
import SlideInBottom from '../utility/slideInBottomAnimation'
import { useInView } from 'react-intersection-observer'
import Card from '../common/card'

const Section16 = props => {
  return (
    <div className={`${props.gradient} relative`}>
      {props.animatedIllustration && (
        <div
          style={{
            position: 'absolute',
            zIndex: 1,
            overflow: 'hidden',
            bottom: 0,
            top: 0,
            height: '100%',
            width: '100%',
          }}
        >
          {props.animatedIllustration}
        </div>
      )}
      <div className="section" style={{ zIndex: 5 }}>
        <div className="md:flex py-12 md:py-24 md:space-x-12 space-y-8 md:space-y-0">
          <div className='md:w-1/3'>
            <SlideInTop>
              <h1 className={`text-3xl font-semibold border-b-2 border-green-400 pb-4 ${props.textColor}`}>{props.sectionTitle}</h1>
            </SlideInTop>
            <SlideInBottom>
              <p className={`mt-4 ${props.textColor}`}>
                {props.sectionDesc}
              </p>
            </SlideInBottom>
            {props.illustration && <div>{props.illustration}</div>}
          </div>         
          <div className='md:w-2/3'>
            <div className="md:grid md:grid-cols-2 md:gap-8 place-items-stretch">
              {props.features.map((feature, idx) => (
                <div key={feature}>
                  <Card
                    cardTitle={props.featureTitle[idx]}
                    cardSubtitle={props.featureDesc[idx]}
                    button={props.featureButton ? props.featureButton[idx] : ''}
                    to={props.featureButtonLink ? props.featureButtonLink[idx] : ''}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
      
  )
}

export default Section16

