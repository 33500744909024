import React from 'react'
import SlideInTop from '../utility/slideInTopAnimation'

const Paragraph2Cols = props => (
  <div className="py-6 md:py-24">
    <div className="md:max-w-5xl md:mx-auto px-8 md:px-0">
      <SlideInTop>
        <h2 className="font-semibold text-3xl md:text-5xl py-12">
        { props.title }
        </h2>
      </SlideInTop>
      <SlideInTop>
        <p className="ml-4 md:ml-32 mb-12 px-16 text-xl py-4 bg-gray-50">
        { props.intro }
        </p>
      </SlideInTop>
      <SlideInTop>
        <div
        className="columns-1 md:columns-2 pl-4 md:pl-16 border-l-2 border-green-400 text-justify"
        >
          <p>{props.text }</p>
        </div>
      </SlideInTop>
    </div>
  </div>
)

export default Paragraph2Cols



