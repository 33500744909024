import React from 'react'
import Card from '../common/card'
import Img from 'gatsby-image'
import { motion } from 'framer-motion'
import SlideInTop from '../utility/slideInTopAnimation'
import ScaleUpInfinity from '../utility/scaleUpInfinityAnimation'
import { useInView } from 'react-intersection-observer'

const Section2 = props => {
  const [ref, inView] = useInView({ threshold: 0.5 })
  return (
    <div className="maingradtl">
      <div className="section">
        <div className='flex flex-col py-36'>
          <div className='md:w-2/3'>
            <SlideInTop>
              <h2 className="text-white text-5xl font-semibold">
                {props.section2Title}
              </h2>
            </SlideInTop>
          </div>
          <div className='md:w-11/12 place-self-end mt-12'>
            <div className='md:flex'>
              <ScaleUpInfinity>
                {props.fixed && <Img fixed={props.fixed} alt={props.alt} />}
              </ScaleUpInfinity>
              <motion.div
                  animate={
                    inView
                      ? {
                          scale: [0, 1.2, 1],
                          opacity: [0, 1],
                        }
                      : {
                          scale: 0,
                          opacity: [0, 0],
                        }
                  }
                  ref={ref}
                  transition={{
                    duration: 1.5,
                    delay: 0.5,
                  }}
                >
                  <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <Card
                      cardTitle={props.card1Title}
                      imageCss={props.card1Imagecss}
                      imageStyle={props.card1Imagestyle}
                      fixedImage={props.card1Fixedimage}
                      alt={props.alt}
                    />
                    <Card
                      cardTitle={props.card2Title}
                      imageCss={props.card2Imagecss}
                      imageStyle={props.card2Imagestyle}
                      fixedImage={props.card2Fixedimage}
                      alt={props.alt}
                    />
                    <Card
                      cardTitle={props.card3Title}
                      imageCss={props.card3Imagecss}
                      imageStyle={props.card3Imagestyle}
                      fixedImage={props.card3Fixedimage}
                      alt={props.alt}
                    />
                    <Card
                    cardTitle={props.card4Title}
                    imageCss={props.card4Imagecss}
                    imageStyle={props.card4Imagestyle}
                    fixedImage={props.card4Fixedimage}
                    alt={props.alt}
                  />
                  </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section2
